<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('lang.Equipmentparameters')"
      :show-close="false"
      width="55%"
      custom-class="seteqcss"
      center
    >
      <div style="color: #f56c6c; margin-bottom: 0.2rem" v-if="activeName == 'first'">
        {{ $t("lang.onlythebit") }}
      </div>
      <div style="color: #f56c6c; margin-bottom: 0.2rem" v-if="activeName == 'second'">
        {{ $t("lang.twothebit") }}
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('lang.Basicsettings')" name="first">
          <el-form :model="device" :rules="rules" ref="ruleForm">
            <div class="setmsg" v-if="device.workStatus == 1">
              <div class="setmsgleft">
                <el-form-item
                  :label="$t('lang.equipmentName') + ':'"
                  prop="name"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.name"
                    disabled
                    autocomplete="off"
                    :placeholder="$t('lang.Pleaseenterthedevicename')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.codingAlgorithm') + ':'"
                  prop="encodeType"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.encodeType"
                    disabled
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in endcodeType"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                    <!-- <el-form-item
                  :label="$t('lang.transportProtocol') + ':'"
                  prop="protocol"
                  class="listmsg"
                >
                  <el-select v-model="device.protocol" disabled popper-class="selectval">
                    <el-option
                      v-for="item in protocol"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item
                  :label="$t('lang.workingMode') + ':'"
                  prop="workMode"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.workMode"
                    disabled
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in workMode"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                
              </div>
              <div class="setmsgright">
                <el-form-item
                  :label="$t('lang.bitRate') + ':'"
                  prop="videoBitrate"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.videoBitrate"
                    type="number"
                    autocomplete="off"
                    :placeholder="$t('lang.Range') + '100 KB – 15 MB'"
                    class="inputCls"
                  >
                    <template slot="append">kbps</template>
                  </el-input>
                  <!--  <span>KB</span> -->
                </el-form-item>
                <el-form-item
                  :label="$t('lang.bufferTime') + ':'"
                  prop="cache"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.cache"
                    type="number"
                    autocomplete="off"
                    @blur="cachbl"
                    :min="500"
                    :placeholder="$t('lang.Range') + '500 – 30000'"
                    class="inputCls-item"
                  >
                    <template slot="append">ms</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Bitratemode') + ':'"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.videoCbr"
                    disabled
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in videoCbr"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item
                  :label="$t('lang.Encodingframerate') + ':'"
                  prop="outputPolicy"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.encodeFps"
                    disabled
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in encodeFps"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
              </div>
            </div>
            <div class="setmsg" v-else>
              <div class="setmsgleft">
                <el-form-item
                  :label="$t('lang.equipmentName') + ':'"
                  prop="name"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.name"
                    autocomplete="off"
                    :placeholder="$t('lang.Pleaseenterthedevicename')"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.codingAlgorithm') + ':'"
                  prop="encodeType"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.encodeType"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in endcodeType"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item
				      :label="$t('lang.transportProtocol') + ':'"
				      prop="protocol"
				      class="listmsg"
				    >
				      <el-select v-model="device.protocol" popper-class="selectval">
				        <el-option
				          v-for="item in protocol"
				          :key="item.value"
				          :value="item.value"
				          :label="item.label"
				        ></el-option>
				      </el-select>
				    </el-form-item> -->
                <!-- <el-form-item :label="$t('lang.transmissionStrategy') + ':'" prop="outputPolicy" class="listmsg">
				      <el-select v-model="device.outputPolicy" popper-class="selectval">
				        <el-option
				          v-for="item in outputPolicy"
				          :key="item.value"
				          :value="item.value"
				          :label="item.label"
				        ></el-option>
				      </el-select>
				    </el-form-item> -->
                <el-form-item
                  :label="$t('lang.workingMode') + ':'"
                  prop="workMode"
                  class="listmsg"
                >
                  <el-select v-model="device.workMode" popper-class="selectval">
                    <el-option
                      v-for="item in workMode"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                
              </div>
              <div class="setmsgright">
                <el-form-item
                  :label="$t('lang.bitRate') + ':'"
                  prop="videoBitrate"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.videoBitrate"
                    type="number"
                    autocomplete="off"
                    :placeholder="$t('lang.Range') + '100 KB – 15 MB'"
                    class="inputCls"
                  >
                    <template slot="append">kbps</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.bufferTime') + ':'"
                  prop="cache"
                  class="listmsg"
                >
                  <el-input
                    v-model="device.cache"
                    type="number"
                    autocomplete="off"
                    @blur="cachbl"
                    :min="500"
                    :placeholder="$t('lang.Range') + '500 – 30000'"
                    class="inputCls-item"
                  >
                    <template slot="append">ms</template>
                  </el-input>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Bitratemode') + ':'"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select v-model="device.videoCbr" popper-class="selectval">
                    <el-option
                      v-for="item in videoCbr"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item
                  :label="$t('lang.Encodingframerate') + ':'"
                  prop="outputPolicy"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.encodeFps"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in encodeFps"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('lang.Audioandvideo')" name="second">
          <el-form
            :model="device"
            :rules="rules"
            status-icon
            ref="ruleFormlsitad"
            class="demo-ruleForm"
          >
            <div class="setmsg">
              <div class="setmsgleft">
                <el-form-item
                  :label="$t('lang.Videoframeratemode')"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.videoEncodeFramerate"
                    :disabled="device.workStatus == 1 || isIos"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in videoEncodeFramerate"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                   fps
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Videoresolution')"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.videoEncodeResolution"
                    :disabled="device.workStatus == 1 || isIos"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in videoEncodeResolution"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Videorotationangle')"
                  prop="videoCbr"
                  class="listmsg"
                  v-if="!isIos"
                >
                  <el-select
                    v-model="device.videoEncodeRotation"
                    :disabled="device.workStatus == 1"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in videoEncodeRotation"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item
                  :label="$t('lang.VIDEODEinterleaving')"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.videoEncodeDeinterlacing"
                    :disabled="device.workStatus == 1"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in videoEncodeDeinterlacing"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item> -->
              </div>
              <div class="setmsgright">
                <el-form-item
                  :label="$t('lang.Audiocodingmode')"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.audioEncodeMode"
                    :disabled="device.workStatus == 1 || isIos"
                    popper-class="selectval"
                    @change="slealllls"
                  >
                    <el-option
                      v-for="item in audioEncodeMode"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Audiocodingrate')"
                  prop="videoCbr"
                  class="listmsg"
                >

                  <el-select
                  v-if="device.audioEncodeMode==1"
                    v-model="device.audioEncodeBitrate"
                    :disabled="device.workStatus == 1"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in audioEncodeBitrate"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                   <el-select
                  v-if="device.audioEncodeMode==0"
                    v-model="device.audioEncodeBitrate"
                    :disabled="device.workStatus == 1"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in audioEncodeBitrateTwo"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                  kb
                </el-form-item>
                <el-form-item
                  :label="$t('lang.Audirate')"
                  prop="videoCbr"
                  class="listmsg"
                >
                  <el-select
                    v-model="device.audioEncodeSamplerate"
                    :disabled="device.workStatus == 1 || isIos"
                    popper-class="selectval"
                  >
                    <el-option
                      v-for="item in audioEncodeSamplerate"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                  Hz
                </el-form-item>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane :label="$t('lang.Logosettings')" name="third">
          <div class="logos">
            <div class="logoLeft">
              <div class="zuoboao">
                ({{ device.osd.logo.x }},{{ device.osd.logo.y }})
              </div>
              <div
                class="centerneirong"
                :style="
                  'width:' +
                  curwidth / 100 +
                  'rem;height:' +
                  curheight / 100 +
                  'rem;'
                "
              >
                <div
                  class="logos"
                  @mousedown="move"
                  :style="
                    'width:' +
                    curwidth / (numberWidth / device.osd.logo.width) / 100 +
                    'rem;height:' +
                    curheight / (numberHeight / device.osd.logo.height) / 100 +
                    'rem;left:' +
                    curwidth / (numberWidth / device.osd.logo.x) / 100 +
                    'rem;top:' +
                    curheight / (numberHeight / device.osd.logo.y) / 100 +
                    'rem;'
                  "
                ></div>
              </div>
              <div class="fenbianlu">({{ allResolution }})</div>
            </div>
            <div class="logoRight">
              <div class="kaiguanlogo">
                <el-checkbox v-model="enable">
                  {{ $t("lang.switch") }}</el-checkbox
                >
              </div>
              <el-form :label-position="'right'" label-width="130px">
                <el-form-item :label="$t('lang.resolvingpower')">
                  <el-select
                    v-model="allResolution"
                    popper-class="selectval"
                    style="width: 100%"
                    @change="Resolutionchange"
                  >
                    <el-option
                      v-for="item in Resolution"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('lang.picture')">
                  <div class="imgadds">
                    <el-input
                      v-model="device.osd.logo.name"
                      autocomplete="off"
                      :placeholder="$t('lang.Picturename')"
                    ></el-input>

                    <el-upload
                      class="upload-demo"
                      ref='upload'
                      :action="uploadUrl + '?mid=' + device.mid"
                      :headers="headers"
                      :show-file-list="false"
                      :limit="1"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      :file-list="fileList"
                    >
                      <span class="spans"
                        ><i class="el-icon-folder-add"></i
                      ></span>
                    </el-upload>
                  </div>
                </el-form-item>
                <div class="initsa">
                  <el-form-item :label="$t('lang.width')" label-width="90px">
                    <el-input
                      v-model="device.osd.logo.width"
                      :disabled="true"
                      type="number"
                    ></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('lang.height')" label-width="90px">
                    <el-input
                      v-model="device.osd.logo.height"
                      :disabled="true"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="initsa" style="padding-left: 0.3rem">
                  <el-form-item label="x" label-width="90px">
                    <el-input
                      v-model="device.osd.logo.x"
                      :max="numberWidth - device.osd.logo.width"
                      :min="0"
                      type="number"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Y" label-width="90px">
                    <el-input
                      v-model="device.osd.logo.y"
                      :max="numberHeight - device.osd.logo.height"
                      :min="0"
                      type="number"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span class="dialog-footer" slot="footer">
        <button @click="dialogVisible = false">
          <p>{{ $t("lang.cancel") }}</p>
        </button>
        <button @click="handleSubmit('ruleForm')">
          <p>{{ $t("lang.submit") }}</p>
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as equipmentApi from "@/api/equipment";
import { uploadtwo } from "@/utils/request";
import bus from "@/utils/bus.js";

export default {
  data() {
    return {
      enable: false,
      dialogVisible: false,
      activeName: "first",
      uploadUrl: uploadtwo,
      headers: {
        Authorization: JSON.parse(sessionStorage.getItem("token")),
      },
      isIos:false,
      fileList: [],
      device: {
        adminEmail: "",
        videoBitrate: "",
        cache: "",
        videoCbr: "",
        encodeFmt: "",
        encodeFps: "",
        encodeType: "",
        hardVer: "",
        mid: "",
        name: "",
        outputPolicy: "",
        protocol: "",
        workMode: "",
        workStatus: "",
        // type:''
        videoEncodeFramerate: "", //帧率模式, 0:auto、1:25、 2:29.97、3 30、4:50、5:59.94、6:60
        videoEncodeResolution: "", //分辨率 0:保持同源、1:1920x1080、2:1280x720、3:720x576、4:720*480
        videoEncodeRotation: "", //旋转角度, 0:不旋转，1:旋转90度，2：旋转180度，3：旋转270度
        videoEncodeDeinterlacing: "", //反交错, 0关闭,1打开
        audioEncodeMode: "", //音频编码模式，0- LC 1- HE
        audioEncodeBitrate: "", //音频编码码率（单位k），64、128、192、256、320
        audioEncodeSamplerate: "", //音频编码采样率，0-48000、1-44100
        osd: {
          //设置
          enable: 0, //开启开关，0关闭，1开启
          canvasWidth: 720, //画布宽度 宽屏320，竖屏180
          canvasHeight: 480, //画布高度 竖屏180，宽屏320
          logo: {
            x: 0, //logo在画布横坐标位置
            y: 0, //logo在画布纵坐标位置
            width: 400, //logo在画布显示宽度
            height: 300, // logo在画布显示高度
            url: "", //logo url地址
            md5: "", //logo md5值
            name: "", //logo名称
          },
        },
      },

      endcodeType: [
        {
          label: "H.264",
          value: 0,
        },
        {
          label: "H.265",
          value: 1,
        },
      ],
      protocol: [
        {
          label: "UDP",
          value: 1,
        },
        {
          label: "TCP",
          value: 2,
        },
      ],

      videoCbr: [
        {
          label: "VBR",
          value: 0,
        },
        {
          label: "CBR",
          value: 1,
        },
      ],
      encodeFmt: [
        {
          label: "720P50",
          value: 0,
        },
        {
          label: "720P60",
          value: 1,
        },
        {
          label: "1080P25",
          value: 2,
        },
        {
          label: "1080P30",
          value: 3,
        },
      ],
      videoEncodeFramerate: [
        {
          label: "auto",
          value: 0,
        },
        {
          label: "25",
          value: 1,
        },
        {
          label: "29.97",
          value: 2,
        },
        {
          label: "30",
          value: 3,
        },
        {
          label: "50",
          value: 4,
        },
        {
          label: "59.94",
          value: 5,
        },
        {
          label: "60",
          value: 6,
        },
      ],
      videoEncodeResolution: [
        {
          label: '保持同源',
          value: 0,
        },
        {
          label: "1920x1080",
          value: 1,
        },
        {
          label: "1280x720",
          value: 2,
        },
        {
          label: "720x576",
          value: 3,
        },
        {
          label: "720x480",
          value: 4,
        },
      ],
      videoEncodeRotation: [
        {
          label: "不旋转",
          value: 0,
        },
        {
          label: "旋转90°",
          value: 1,
        },
        {
          label: "旋转180°",
          value: 2,
        },
        {
          label: "旋转270°",
          value: 3,
        },
      ],
      videoEncodeDeinterlacing: [
        {
          label: "关闭",
          value: 0,
        },
        {
          label: "打开",
          value: 1,
        },
      ],
      audioEncodeMode: [
        {
          label: "AAC-LC",
          value: 0,
        },
        {
          label: "AAC-HE",
          value: 1,
        },
      ],
      audioEncodeBitrate: [
        {
          label: "64",
          value: 64,
        },
        {
          label: "128",
          value: 128,
        },
        // {
        //   label: "192",
        //   value: 192,
        // },
        // {
        //   label: "256",
        //   value: 256,
        // },
        // {
        //   label: "320",
        //   value: 320,
        // },
      ],
      audioEncodeBitrateTwo: [
        {
          label: "64",
          value: 64,
        },
        {
          label: "128",
          value: 128,
        },
        {
          label: "192",
          value: 192,
        },
        {
          label: "256",
          value: 256,
        },
        {
          label: "320",
          value: 320,
        },
      ],
      audioEncodeSamplerate: [
        {
          label: "48000",
          value: 0,
        },
        {
          label: "44100",
          value: 1,
        },
      ],
      allResolution: "",
      Resolution: [
        {
          label: "720x480",
          value: "720x480",
        },
        {
          label: "720x576",
          value: "720x576",
        },
        {
          label: "1280x720",
          value: "1280x720",
        },
        {
          label: "1920x1080",
          value: "1920x1080",
        },
        // {
        //   label: "3840x2160",
        //   value: "3840x2160",
        // },
      ],
      curwidth: 455,
      curheight: 300,
      scale: 1,
      numberWidth: 0,
      numberHeight: 0,
    };
  },
  computed: {
    outputPolicy() {
      return [
        {
          label: this.$t("lang.Qualityfirst"),
          value: 0,
        },
        {
          label: this.$t("lang.Delaypriority"),
          value: 1,
        },
        {
          label: this.$t("lang.delay"),
          value: 2,
        },
      ];
    },
    encodeFps() {
      return [
        {
          label: this.$t("lang.Fullframerate"),
          value: 0,
        },
        {
          label: this.$t("lang.Halfframerate"),
          value: 1,
        },
      ];
    },
    rules() {
      return {
        name: {
          required: true,
          message: this.$t("lang.Pleaseenterthedevicename"),
          trigger: "blur",
        },
        encodeType: {
          required: true,
          message: this.$t("lang.Pleaseselecttheencodingalgorithm"),
          trigger: "blur",
        },
        protocol: {
          required: true,
          message: this.$t("lang.Pleaseselecttransportprotocol"),
          trigger: "blur",
        },
        outputPolicy: {
          required: true,
          message: this.$t("lang.Pleaseselectoutputpolicy"),
          trigger: "blur",
        },
        workMode: {
          required: true,
          message: this.$t("lang.Pleaseselectworkingmode"),
          trigger: "blur",
        },
        videoBitrate: {
          required: true,
          message: this.$t("lang.Range") + "100 KB – 15 MB",
          trigger: "blur",
        },
        encodeFmt: {
          required: true,
          message: this.$t("lang.Pleaseselectthecodingsystem"),
          trigger: "blur",
        },
        videoCbr: {
          required: true,
          message: this.$t("lang.Pleaseselectthecoderatemode"),
          trigger: "blur",
        },
        encodeFps: {
          required: true,
          message: this.$t("lang.Pleaseselectencodingframerate"),
          trigger: "blur",
        },
        cache: {
          required: true,
          message: this.$t("lang.Range") + "0 - 30000ms",
          trigger: "blur",
        },
      };
    },
    workMode() {
      return [
        {
          label: this.$t("lang.livebroadcast"),
          value: 0,
        },
        {
          label: this.$t("lang.Losslessrecordingandbroadcasting"),
          value: 1,
        },
      ];
    },
  },
  created() {
    bus.$on("langchange", () => {
      this.videoEncodeResolution[0].label = this.$t("lang.Keephomology");
      this.videoEncodeRotation = [
        {
          label: this.$t("lang.Norotation"),
          value: 0,
        },
        {
          label: this.$t("lang.Rotateone"),
          value: 1,
        },
        {
          label: this.$t("lang.Rotatetwo"),
          value: 2,
        },
        {
          label: this.$t("lang.Rotatether"),
          value: 3,
        },
      ];
      this.videoEncodeDeinterlacing = [
        {
          label: this.$t("lang.close"),
          value: 0,
        },
        {
          label: this.$t("lang.open"),
          value: 1,
        },
      ];
      // this.workMode[1].label = this.$t("lang.Losslessrecordingandbroadcasting");
      // // this.workMode[2].label = this.$t("lang.filetransfer");
      // this.outputPolicy[0].label = this.$t("lang.Qualityfirst");
      // this.outputPolicy[1].label = this.$t("lang.Delaypriority");
      // this.outputPolicy[2].label = this.$t("lang.delay");
      // this.encodeFps[0].label = this.$t("lang.Fullframerate");
      // this.encodeFps[1].label = this.$t("lang.Halfframerate");
    });
  },
  methods: {
    slealllls(){
      this.device.audioEncodeBitrate = "";
    },
    //缓冲失去焦点
    cachbl() {
      if (
        this.device.cache >= 500 &&
        this.device.cache <= 30000
      ) {
        
      } else {
        this.device.cache = 500
        this.$message.error(this.$t("lang.Pleaseenters"));
      }
    },
    // 拖拽
    move(e) {
      console.log("我在外面，我被拽着走");
      let odiv = e.target; //获取目标元素

      //算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let disY = e.clientY - odiv.offsetTop;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let top = e.clientY - disY;

        //绑定元素位置到positionX和positionY上面
        this.positionX = top;
        this.positionY = left;

        //移动当前元素
        // console.log(
        //   this.curwidth / (this.numberWidth / this.device.osd.logo.width)
        // );
        // console.log(
        //   this.curheight / (this.numberHeight / this.device.osd.logo.height)
        // );

        let minwidth =
          this.curwidth / (this.numberWidth / this.device.osd.logo.width);
        if (left <= 0) {
          odiv.style.left = 0 + "px";
          left = 0;
        } else if (left >= this.curwidth - minwidth) {
          odiv.style.left = this.curwidth - minwidth + "px";
          left = this.curwidth - minwidth;
        } else {
          odiv.style.left = left + "px";
        }
        let minheight =
          this.curheight / (this.numberHeight / this.device.osd.logo.height);
        if (top <= 0) {
          odiv.style.top = 0 + "px";
          top = 0;
        } else if (top >= this.curheight - minheight) {
          odiv.style.top = this.curheight - minheight + "px";
          top = this.curheight - minheight;
        } else {
          odiv.style.top = top + "px";
        }
        // console.log(left,top);
        // console.log(disX)
        // console.log(this.numberWidth / left)
        // console.log(this.numberWidth - this.device.osd.logo.width,this.numberHeight - this.device.osd.logo.height)
        this.device.osd.logo.x = Math.round(
          this.numberWidth / (this.curwidth / left)
        );
        this.device.osd.logo.y = Math.round(
          this.numberHeight / (this.curheight / top)
        );
        // curwidth / (numberWidth / device.osd.logo.x)
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    //上传成功
    handleAvatarSuccess(res, file) {
      this.$refs.upload.clearFiles()
      let url = JSON.parse(res.data);
      if (url.result == 0) {
        this.device.osd.logo.name = url.download_url;
        this.device.osd.logo.url = url.download_url;
        this.device.osd.logo.md5 = url.md5;
        this.device.osd.logo.width = file.width;
        this.device.osd.logo.height = file.height;
        this.$message({
          message: this.$t("lang.Uploadsucceeded"),
          type: "success",
        });
      } else {
        this.$message({
          message: this.$t("lang.imgfenbian"),
          type: "error",
        });
      }
    },
    //上传之前
    beforeAvatarUpload(file) {
      console.log(file);

      return new Promise((resolve, reject) => {
        const url = window.URL || window.webkitURL;
        const img = new Image();

        img.onload = function () {
          file.width = img.width;
          file.height = img.height;
        };
        img.src = url.createObjectURL(file);
        resolve();
      }).then(() => {
        this.fileList.push(file);
      });
    },
    // 选择画布分辨率
    Resolutionchange(e) {
      this.device.osd.logo.y = 0;
      this.device.osd.logo.x = 0;
      let strs = e.split("x");
      let numberWidth = Number(strs[0]);
      let numberHeight = Number(strs[1]);
      this.numberWidth = numberWidth;
      this.numberHeight = numberHeight;
      let nums = numberWidth / numberHeight;
      this.scale = nums;
      this.curheight = this.curwidth / nums;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    open(item, index) {
      //console.log(item)
      this.videoEncodeResolution[0].label = this.$t("lang.Keephomology");
      this.videoEncodeRotation = [
        {
          label: this.$t("lang.Norotation"),
          value: 0,
        },
        {
          label: this.$t("lang.Rotateone"),
          value: 1,
        },
        {
          label: this.$t("lang.Rotatetwo"),
          value: 2,
        },
        {
          label: this.$t("lang.Rotatether"),
          value: 3,
        },
      ];



      if (index != 1) {
        this.dialogVisible = true;
      }
      if(item.deviceStatus.devType == 'UVIOS'){
        this.isIos = true
      }
      this.device.adminEmail = JSON.parse(sessionStorage.getItem("user")).email;
      this.device.name = item.name;
      this.device.mid = item.mid;
      this.device.videoBitrate = item.deviceConfigDto.videoBitrate;
      this.device.cache = item.deviceConfigDto.cache;
      this.device.videoCbr = item.deviceConfigDto.videoCbr;
      this.device.encodeFmt = item.deviceConfigDto.encodeFmt;
      this.device.encodeFps = item.deviceConfigDto.encodeFps;
      this.device.encodeType = item.deviceConfigDto.encodeType;
      this.device.outputPolicy = item.deviceConfigDto.outputPolicy;
      this.device.protocol = item.deviceConfigDto.protocol;
      this.device.workMode = item.deviceConfigDto.workMode;
      this.device.workStatus =
        item.deviceRealtimeInfoDto.workModeStatus.workStatus;
      //新增模块
      this.device.videoEncodeFramerate =
        item.deviceConfigDto.videoEncodeFramerate;
      this.device.videoEncodeResolution =
        item.deviceConfigDto.videoEncodeResolution;
      this.device.videoEncodeRotation =
        item.deviceConfigDto.videoEncodeRotation;
      this.device.videoEncodeDeinterlacing =
        item.deviceConfigDto.videoEncodeDeinterlacing;
        if(item.deviceConfigDto.audioEncodeMode == 2){
           this.device.audioEncodeMode = 0
        }else{
          this.device.audioEncodeMode = item.deviceConfigDto.audioEncodeMode;
        }
      this.device.audioEncodeBitrate = item.deviceConfigDto.audioEncodeBitrate;
      this.device.audioEncodeSamplerate =
        item.deviceConfigDto.audioEncodeSamplerate;
      // this.device.osd = item.deviceConfigDto.osd;
      if (item.deviceConfigDto.osd.enable) {
        this.device.osd.enable = item.deviceConfigDto.osd.enable;
      }
      if (item.deviceConfigDto.osd.canvasWidth) {
        this.device.osd.canvasWidth = item.deviceConfigDto.osd.canvasWidth;
      }else{
        item.deviceConfigDto.osd.canvasWidth = 1920
        this.device.osd.canvasWidth = 1920
      }
      if (item.deviceConfigDto.osd.canvasHeight) {
        this.device.osd.canvasHeight = item.deviceConfigDto.osd.canvasHeight;
      }else{
        item.deviceConfigDto.osd.canvasHeight = 1080
        this.device.osd.canvasWidth = 1080
      }
      if (item.deviceConfigDto.osd.logo.length > 0) {
        this.device.osd.logo = item.deviceConfigDto.osd.logo[0];
      }
      this.scale =
        item.deviceConfigDto.osd.canvasWidth /
        item.deviceConfigDto.osd.canvasHeight;
      this.enable = this.device.osd.enable == 1 ? true : false;
      this.numberWidth = item.deviceConfigDto.osd.canvasWidth;
      this.numberHeight = item.deviceConfigDto.osd.canvasHeight;
      if(!item.deviceConfigDto.osd.canvasWidth && item.deviceConfigDto.osd.canvasHeight){
        this.allResolution ='1920x1080'
      }else{
        this.allResolution =
        item.deviceConfigDto.osd.canvasWidth +
        "x" +
        item.deviceConfigDto.osd.canvasHeight;
      }
      
      this.curheight = this.curwidth / this.scale;

      if (
        this.device.workMode === 1 &&
        item.deviceConfigDto.recordStatus === 1
      ) {
        this.device.recordStatus = 0;
      } else {
        this.device.recordStatus = item.deviceConfigDto.recordStatus;
      }
    },
    handleSet(e, item) {
      if (e) {
        this.open(item, 1);
      }
      let arrscarvs = this.allResolution.split("x");
      this.device.osd.canvasWidth = arrscarvs[0];
      this.device.osd.canvasHeight = arrscarvs[1];
      let arrs = [];
      arrs.push(this.device.osd.logo);
      this.enable ? (this.device.osd.enable = 1) : (this.device.osd.enable = 0);
      this.device.osd.logo = arrs;
      equipmentApi.setDevice(this.device).then(() => {
        this.$message({
          message: this.$t("lang.Setsuccessfully"),
          type: "success",
        });
        this.$emit("refreshDeviceMsg", this.device);

        this.dialogVisible = false;
      });
    },
    handleSubmit(formName) {
      if(this.device.audioEncodeBitrate == ""){
        this.$message({
          message: this.$t("lang.Pleasecompletetheinformation"),
          type: "error",
        });
        return
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.handleSet();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.setmsg {
  display: flex;
  // justify-content: space-around;
  .setmsgleft {
    margin-right: 0.3rem;
  }
}
/deep/.el-tabs__item.is-active {
  color: #438749;
}
/deep/.el-tabs__item:hover {
  color: #438749;
}
/deep/.el-tabs__active-bar {
  background: #438749;
}
/deep/.el-input__inner {
  font-size: 0.15rem;
  background-color: #fff;
}
.inputCls {
  /deep/.el-input__inner {
    width: 1.3rem;
  }
  /deep/.el-input-group__append {
    padding: 0 0.1rem;
  }
}
.inputCls-item {
  /deep/.el-input__inner {
    width: 1.4rem;
  }
  /deep/.el-input-group__append {
    padding: 0 0.13rem;
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #ebebeb;
  border-color: #d7d7d7;
  color: #c8c8c8;
}
.dialog-footer {
  display: inline-block;
  button:first-child {
    background-color: #fff;
  }
}
.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  .logoLeft {
    width: 50%;
    color: #000000;
    .zuoboao {
      padding: 0.1rem 0;
    }
    .centerneirong {
      // width: 4.45rem;
      // height: 3rem;
      background: #000000;
      position: relative;
      .logos {
        position: absolute;
        left: 0;
        right: 0;
        width: 0.5rem;
        height: 0.5rem;
        border: 0.01rem solid #ffffff;
      }
    }
    .fenbianlu {
      text-align: right;
      padding: 0.1rem 0;
    }
  }
  .logoRight {
    width: 50%;
    padding-left: 0.2rem;
    .kaiguanlogo {
      font-weight: 600;
      padding: 0.2rem 0 0.2rem;
      margin-left: 1.2rem;
    }
    .imgadds {
      display: flex;
      align-items: center;
      .spans {
        font-size: 0.3rem;
        padding: 0 0.06rem;
      }
    }
    .initsa {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>