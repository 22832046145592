<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="4.18rem"
      top="2rem"
      :title="$t('lang.Removedevice')"
      :show-close="false"
      custom-class="deleteeq"
    >
      <p>{{$t('lang.AfteryouwillunbinditAreyousure')}}</p>
      <div>
        <span class="dialog-footer">
          <button @click="dialogVisible = false"><p>{{$t('lang.cancel')}}</p></button>
          <button @click="handleSubmit"><p>{{$t('lang.determine')}}</p></button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as equipmentApi from "@/api/equipment";
  export default {
    data(){
      return{
        dialogVisible:false,
        device:{}
      }
    },
    methods:{
      open(item){
        this.dialogVisible = true;
        this.device = item
      },
      handleSubmit(){
        let email = JSON.parse(sessionStorage.getItem("user")).email;
        let params = {adminEmail:email,mid:this.device.mid}
        equipmentApi.unbinding(params).then(res => {
          this.$message({
            message: this.$t('lang.Successfullyunbound'),
            type: 'success'
          });
          this.$emit("refreshDeviceMsg")
          this.dialogVisible = false;
        })
      }
    }
  }
</script>

<style lang="less" scoped>

</style>