<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="4.75rem"
      top="1rem"
      :title="$t('lang.importScheme')"
      :show-close="false"
      custom-class="choiceurl"
    >
      <div v-if="list.length">
        <el-radio-group v-model="params.id" style="margin-top: 20px">
          <div class="urlmsg" v-for="(item, index) of list" :key="index">
            <div class="urltitle">
              <el-radio @change="radioval" :label="item.id" fill="#fff">{{ item.name }}</el-radio>
              <img :class="item.showUrl?'openurl':''" @click="openurl(index)" src="@/assets/imgs/zhanshi.png"/>
            </div>
            <div class="urlcontent" v-show="item.showUrl">
              <p v-for="(items, indexs) of item.nameUrls" :key="indexs">
                {{ items.url }}
              </p>
            </div>
          </div>
          <div>
            <span class="dialog-footer">
              <button @click="dialogVisible = false">
                <p>{{ $t("lang.cancel") }}</p>
              </button>
              <button @click="handleSubmit">
                <p>{{ $t("lang.Confirmimport") }}</p>
              </button>
            </span>
          </div>
        </el-radio-group>
      </div>
      <div class="blank-wrapper" v-else>
        <p>{{ $t("lang.Noschemetoimport") }}</p>
        <el-button size="small" @click="goAddPlan">{{
          $t("lang.Gotoadd")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as userApi from "@/api/apis";
import * as equipmentApi from "@/api/equipment";
export default {
  data() {
    return {
      dialogVisible: false,
      list: [],
      checklist: "",
      params: {
        deviceMid: "",
        email: "",
        id: "",
        pullUrl: [],
      },
    };
  },
  methods: {
    async getAllPlan() {
      let email = JSON.parse(sessionStorage.getItem("user")).email;
      let params = { email: email };
      let { data } = await userApi.solList(params);
      if (data !== null) {
        data.forEach((item) => {
          item.showUrl = false;
        });
        this.list = data.filter((item) => item.name !== null);
      }
    },
    open(item, pullstreamout) {
      this.dialogVisible = true;
      this.params.deviceMid = item.mid;
      this.params.pullUrl = [];
      pullstreamout.forEach((ibs,index) => {
				this.params.pullUrl.push({url:ibs.url,type:ibs.type,enable:''});
				if(ibs.isfor){
					this.params.pullUrl[index].enable=1
				}else{
					this.params.pullUrl[index].enable=0
				}
      });
      this.params.email = JSON.parse(sessionStorage.getItem("user")).email;
			this.params.id=''
      this.getAllPlan();
    },
    goAddPlan() {
			this.$router.push({
				path:'/nav/personal',
				query:{ind:2}
			})
    },
    radioval(val) {
			console.log(val)
      this.checklist = val;
    },
    openurl(index) {
      this.list[index].showUrl = !this.list[index].showUrl;
    },
    handleSubmit() {
      console.log(this.checklist);
      if (this.checklist == "") {
        this.$message({
          message: this.$t("lang.sliceoimport"),
          type: "error",
        });
        return;
      }
      equipmentApi.importPlan(this.params).then(() => {
        this.$message({
          message: this.$t("lang.Importsucceeded"),
          type: "success",
        });
        this.$emit("refreshDeviceMsg");
        this.dialogVisible = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.urlmsg {
  width: 3.6rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.24rem;
  .urltitle {
    display: flex;
    justify-content: space-between;
    img {
      width: 0.16rem;
      height: 0.16rem;
      position: relative;
      top: 2px;
    }
		.openurl{
			transform: rotate(85deg);
		}
  }
  .urlcontent {
    display: flex;
    flex-direction: column;
    width: 2.96rem;
    background: rgba(221, 221, 221, 0.55);
    border-radius: 2px;
    padding: 0.13rem 0.36rem 0px 0.2rem;
    margin: 0.13rem 0px 0.19rem 0px;
    p {
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
      margin-bottom: 0.13rem;
    }
  }
}
.blank-wrapper {
  text-align: center;
  margin-top: 0.3rem;
  p {
    font-weight: 600;
    font-size: 0.16rem;
  }
  .el-button {
    margin-top: 0.2rem;
    background-color: #0a0f30;
    border: none;
    color: #fff;
  }
}
</style>