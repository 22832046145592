<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="5.47rem"
      top="1rem"
      :title="$t('lang.Addaddress')"
      :show-close="false"
      custom-class="pushUrlName"
    >
      <div class="addUrlName">
        <div class="pushmsg">
          <label for="pushurl">{{ $t("lang.address") }}:</label>
          <input id="pushurl" v-model="urlParams.url" :maxlength="256" :placeholder="$t('lang.Pleaseentertheaddress')"/>
          <div class="radio" @click="handleShowUrl"><p>+</p></div>
        </div>
        <div class="keepurl">
          <el-checkbox v-model="isRegularUrl"><p>{{ $t("lang.Savetocommonstreamingaddress") }}</p></el-checkbox>
        </div>
      </div>
      <div>
        <span class="dialog-footer">
          <button @click="dialogVisible = false">
            <p>{{ $t("lang.cancel") }}</p>
          </button>
          <button @click="handleAddUrl">
            <p>{{ $t("lang.Confirmadds") }}</p>
          </button>
        </span>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="selectAddress"
      width="5.5rem"
      top="1rem"
      :title="$t('lang.Selectcommonstreamingaddress')"
      :show-close="false"
      custom-class="importplan"
    >
      <div v-if="regularUrlList.length" class="tuil">
        <div class="choiceplan">
          <el-radio-group v-model="selectedUrl">
            <el-radio
              v-for="(item, index) of regularUrlList"
              :key="index"
              :label="item"
              >{{ item.url }}</el-radio
            >
          </el-radio-group>
        </div>
        <div>
          <span class="dialog-footer">
            <button @click="selectAddress = false">
              <p>{{ $t("lang.cancel") }}</p>
            </button>
            <button @click="handleAdd">
              <p>{{ $t("lang.Confirmadds") }}</p>
            </button>
          </span>
        </div>
      </div>
      <div class="blank-wrapper" v-else>
        <p>{{ $t("lang.Nocommonstreamingaddress") }}</p>
        <el-button size="small" @click="goAddPlan">{{
          $t("lang.Gotoadd")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as userApi from "@/api/apis";
export default {
  data() {
    return {
      dialogVisible: false,
      selectAddress: false,
      isRegularUrl: false,
      urlParams: {
        name: "",
        url: "",
      },
      regularUrlList: [],
      checkdefs: [],
      importlist: [],
      urlObj: {},
      selectedUrl: {},
      device: {},
      urldata: "",
      pullUrl: [],
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.urlParams.name = "";
        this.urlParams.url = "";
      }
    },
  },
  methods: {
    open(item, data, pullstreamout) {
			this.selectedUrl={}
			this.isRegularUrl=false
      this.dialogVisible = true;
      this.device = item;
      this.urldata = data;
      this.pullUrl = [];
      pullstreamout.forEach((ibs,index) => {
        this.pullUrl.push({url:ibs.url,type:ibs.type,enable:''});
				if(ibs.isfor){
					this.pullUrl[index].enable=1
				}else{
					this.pullUrl[index].enable=0
				}
      });
      this.getUrlList();
    },
    async getUrlList() {
      let email = JSON.parse(sessionStorage.getItem("user")).email;
      let params = { email: email };
      let { data } = await userApi.solList(params);
      if (data !== null) {
        let urlList = data.filter((item) => item.name === null);
				//console.log(urlList)
				if(urlList.length>0){
					this.regularUrlList = urlList[0].nameUrls;
				}
      }
    },
    goAddPlan() {
			this.$router.push({
				path:'/nav/personal',
				query:{ind:2,type:1}
			})
    },
    handleShowUrl() {
      this.dialogVisible = false;
      this.selectAddress = true;
    },
    keep(val) {
      this.checkdef = val;
    },
    handleAdd() {
      this.selectAddress = false;
      this.dialogVisible = true;
      this.urlParams.solutionId = this.selectedUrl.solutionId;
      this.urlParams.url = this.selectedUrl.url;
    },
    handleAddUrl() {
			//console.log(this.isRegularUrl)
      this.isRegularUrl ? this.handleAddRegular() : this.handleAddUsual();
    },
    //添加到常用地址
    handleAddRegular() {
      let email = JSON.parse(sessionStorage.getItem("user")).email;
      let deviceMid = this.device.mid;
			let sk=/^[^\s]*$/
			if(!sk.test(this.urlParams.url)){
				this.$message.error(this.$t("lang.Pleaseenterthecorrects"));
				return;
			}
			let urlRegex =
			/(udp|rtmp|srt|rtmps+):\/\/\b[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62}){3,10}\b(:(6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]|[0-5]?\d{0,4}))?([\/a-z,A-Z]*)/g;
      if (!urlRegex.test(this.urlParams.url)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrects"));
        return;
      } else {
        let params = {
          deviceMid: deviceMid,
          email: email,
          name: "",
          nameUrls: [{ solutionId: '', url: this.urlParams.url }],
        };
        userApi.solAdd(params).then((res) => {
          this.$message({
            message: this.$t("lang.addsuccessaa"),
            type: "success",
						duration:3000
          });
					this.handleAddUsual();
          // this.$emit("refreshDeviceMsg");
          // this.dialogVisible = false;
        });
      }
    },
    //添加普通地址
    handleAddUsual() {
      //console.log(this.urlParams);
      let email = JSON.parse(sessionStorage.getItem("user")).email;
      let deviceMid = this.device.mid;
			let sk=/^[^\s]*$/
			if(!sk.test(this.urlParams.url)){
				this.$message.error(this.$t("lang.Pleaseenterthecorrects"));
				return;
			}
      let urlRegex =
			/(udp|rtmp|srt|rtmps+):\/\/\b[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62}){3,10}\b(:(6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5]|[0-5]?\d{0,4}))?([\/a-z,A-Z]*)/g;
			if (!urlRegex.test(this.urlParams.url)) {
        this.$message.error(this.$t("lang.Pleaseenterthecorrects"));
        return;
      } else {
        let soe = [];
				let filgs=true
        this.urldata.forEach((item) => {
					if(item===this.urlParams.url){
						filgs=false
					}else{
						soe.push({ solutionId: "", url: item });
					}
        });
				if(!filgs){
					this.$message.error(this.$t("lang.Thenameofpushstreamisrepeated"))
					return false
				}
				soe.push({ solutionId: this.urlParams.solutionId, url: this.urlParams.url });
		
        let params = {
          deviceMid: deviceMid,
          email: email,
          name: "",
          nameUrls: soe,
          pullUrl: this.pullUrl,
        };
        userApi.addEqUrl(params).then((res) => {
          this.$message({
            message: this.$t("lang.addsuccessbb"),
            type: "success",
          });
        });
        this.dialogVisible = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.addUrlName {
  padding: 0.5rem 0.3rem 0rem 0.4rem;
  .radio {
    width: 0.3rem;
    height: 0.3rem;
    position: absolute;
    background: #f8f8f8;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    text-align: center;

    right: 0.03rem;
    top: 0.08rem;
    p {
      color: #333333;
      font-size: 0.24rem;
      line-height: 0.26rem;
    }
  }
  .pushmsg {
    margin-bottom: 0.23rem;
    position: relative;
		display: flex;
		align-items: center;
    label {
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-right: 0.18rem;
    }
    input {
      width: 3.73rem;
      height: 0.46rem;
      outline-style: none;
      border: none;
      background: #f8f8f8;
      border: 1px solid #d5d5d5;
      border-radius: 2px;
      font-size: 0.14rem;
      font-family: PingFang SC;
      font-weight: 500;
      text-indent: 0.18rem;
      padding-right: 40px;
      box-sizing: border-box;
    }
  }
  .keepurl {
    padding-left: 0.78rem;
    p {
      font-size: 0.16rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #438749;
      line-height: 0.35rem;
    }
  }
}
.blank-wrapper {
  text-align: center;
  margin-top: 0.3rem;
  p {
    font-weight: 600;
    font-size: 0.16rem;
  }
  .el-button {
    margin-top: 0.2rem;
    background-color: #0a0f30;
    border: none;
    color: #fff;
  }
}
.choiceplan {
  .el-radio-group {
    height: 2rem;
  }
}
.tuil {
  width: 100%;
  height: 3rem;
  padding: 0 10px;
  box-sizing: border-box;
  /deep/ .el-radio {
    margin-bottom: 20px;
    margin-right: 0;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }
  /deep/ .el-radio__label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: normal;
    display: inline-block;
  }
}
</style>